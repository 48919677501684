import './global.css'
import { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { SnackbarProvider } from 'notistack'
import { AuthContextProvider } from '../hooks/AuthContext'
import { RoomContextProvider } from '../hooks/Room'
import { getSiteName } from '../lib/env'
import * as fbq from '../lib/fpixel'
import { GoogleOAuthProvider } from '@react-oauth/google'
import 'react-phone-number-input/style.css'
import { UserInteractionProvider } from '../hooks/UserInteractionContext'
import { useEnv } from '../useEnv'

const loadIntercomScript = (appId) => {
  window.intercomSettings = {
    app_id: appId,
  };
  (function () {
    const w = window
    const ic = w.Intercom
    const i: (...args: any[]) => void = function (...args) {
      i.c(...args)
    }
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', intercomSettings)
    } else {
      const d = document
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
      const l = function () {
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = `https://widget.intercom.io/widget/${appId}`
        const x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      if (d.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  }())
}

const useIntercom = (appId) => {
  useEffect(() => {
    if (!window.Intercom) {
      loadIntercomScript(appId)
    } else {
      window.Intercom('reattach_activator')
      window.Intercom('update', window.intercomSettings)
    }
  }, [appId])
}

function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const env = useEnv()
  const { NEXT_PUBLIC_GOOGLE_CLIENT_ID, NEXT_PUBLIC_HEAPANALYTICS_KEY } = env
  useIntercom('domlq26c')
  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview()

    const handleRouteChange = () => {
      fbq.pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // enable service worker for Progressive Web App
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(
          (registration) => {
            console.log('Service Worker registration successful with scope: ', registration.scope)
          },
          (err) => {
            console.error('Service Worker registration failed: ', err)
          },
        )
      })
    }
  }, [])
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta content="IE=Edge" httpEquiv="X-UA-Compatible" />
        <meta name="google" content="notranslate" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no"
        />
        <meta
          name="description"
          content="Learn Music Online. Here."
        />
        <meta name="copyright" content="LaLa, 2024" />
        <meta name="author" content="LaLa Co." />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <link rel="apple-touch-icon" sizes="180x180" href="/logo_white.png" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link rel="icon" href="/favicon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#28a745" />
        <meta name="apple-mobile-web-app-title" content={getSiteName()} />
        <meta name="application-name" content={getSiteName()} />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
        <meta name="theme-color" content="#28a745" />
        <meta property="og:image" content="https://app.meetlala.io/api/v1/main/page-preview/https://meetlala.io" key="preview" />
        <link rel="canonical" href="/" data-react-helmet="true" />
      </Head>
      {/* Global Site Code Pixel - Facebook Pixel */}
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', ${fbq.FB_PIXEL_ID});
                    fbq('track', 'PageView');
                  `,
        }}
      />
      <Script id="hotjar">
        {`
           (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3448908,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </Script>
      <Script
        id="google"
        src="https://www.googletagmanager.com/gtag/js?id=G-8T7SZP4TR7"
      />
      <Script id="_google" strategy="afterInteractive">
        {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
         
           gtag('config', 'G-8T7SZP4TR7');
           const query = new URLSearchParams(window.location.search);
           const utmObject = {}
           for (const p of query.entries()) {
            if (p[0].startsWith('utm_')) {
                utmObject[p[0]] = p[1]
            }
           }
           gtag('utmQueries', utmObject);
        `}
      </Script>
      <Script type="text/javascript">
        {`
            window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
            heap.load("${NEXT_PUBLIC_HEAPANALYTICS_KEY}");
            `}
      </Script>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <GoogleOAuthProvider
          clientId={NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
          <UserInteractionProvider>
            <AuthContextProvider>
              <RoomContextProvider>
                <Component {...pageProps} />
              </RoomContextProvider>
            </AuthContextProvider>
          </UserInteractionProvider>
        </GoogleOAuthProvider>
      </SnackbarProvider>
    </>
  )
}

export default App
